import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleImageBlock from "../components/titleImageBlock"
import ContentSlicer from "../components/contentSlicer"

export const postQuery = graphql`
  query PostQuery($id: String) {
    prismic {
      allPosts(id: $id) {
        edges {
          node {
            _meta {
              lastPublicationDate
            }
            body {
              ... on PRISMIC_PostBodyContent_block {
                type
                label
                primary {
                  content
                }
              }
              ... on PRISMIC_PostBodyVideo_block {
                type
                label
                primary {
                  video_title
                  video_url
                  location
                }
              }
              ... on PRISMIC_PostBodyReact_component {
                type
                label
                primary {
                  component_name
                }
              }
            }
            featured_image
            featured_image_caption
            page_title
            seo_title
            seo_description
            _meta {
              id
              uid
            }
          }
        }
      }
    }
  }
`

const Post = props => {
  const node = props.data.prismic.allPosts.edges[0].node

  return (
    <Layout>
      <Seo title={node.seo_title} description={node.seo_description} />
      <TitleImageBlock
        title={node.page_title[0].text}
        image={node.featured_image}
        caption={node.featured_image_caption}
        updatedDate={node._meta.lastPublicationDate}
      />
      <ContentSlicer body={node.body} />
    </Layout>
  )
}

export default Post
